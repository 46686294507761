import React from 'react';
import { Helmet } from 'react-helmet';

import { css } from '@emotion/core';

import { Footer } from '../components/Footer';
import SiteNav from '../components/header/SiteNav';
import { PostFullContent } from '../components/PostContent';
import { Wrapper } from '../components/Wrapper';
import IndexLayout from '../layouts';
import {
  inner,
  outer,
  SiteArchiveHeader,
  SiteHeader,
  SiteMain,
  SiteNavMain,
} from '../styles/shared';
import { NoImage, PostFull, PostFullHeader, PostFullTitle } from '../templates/post';
import { colors } from '../styles/colors';

const PageTemplate = css`
  .site-main {
    margin-top: 64px;
    padding-bottom: 4vw;
    background: #fff;
  }

  @media (prefers-color-scheme: dark) {
    .site-main {
      /* background: var(--darkmode); */
      background: ${colors.darkmode};
    }
  }
`;

const PrivacyPolicy: React.FC = () => (
  <IndexLayout>
    <Helmet>
      <title>Privacy Policy</title>
    </Helmet>
    <Wrapper css={PageTemplate}>
      <header className="site-archive-header no-image" css={[SiteHeader, SiteArchiveHeader]}>
        <div css={[outer, SiteNavMain]}>
          <div css={inner}>
            <SiteNav isHome={false} />
          </div>
        </div>
      </header>
      <main id="site-main" className="site-main" css={[SiteMain, outer]}>
        <div css={inner}>
          <article className="post page" css={[PostFull, NoImage]}>
            <PostFullHeader className="post-full-header">
              <PostFullTitle className="post-full-title">Privacy Policy</PostFullTitle>
            </PostFullHeader>

            <PostFullContent className="post-full-content">
              <div className="post-content">
                <h5>Privacy Policy</h5>
                <div>
                  <p>
                    Privacy is important to us and we have added this privacy page, to explain a few
                    quick things about the site and what info we collect, which is not much at all.
                  </p>

                  <p>
                    <strong>What Info Do We Collect and Why?</strong>
                  </p>

                  <p>
                    Whenever you comment on this blog you will be required to leave your email
                    address. We only collect this so we can email you about your comment if need be.
                    However, if you leave your name and email in the opt-in form box, you will then
                    be sent a confirmation email, and if you click that, then your email will be
                    moved to my email marketing list, so I can send you important updates. We will
                    never ever sell your contact info or give it away to anyone, period!!
                  </p>

                  <p>
                    <strong>Cookies</strong>
                  </p>

                  <p>
                    This blog uses a technology known as “cookies” and web server log files to
                    collect info, such as date and time of your visit, what pages you looked at and
                    the time you spent on each page, etc..
                  </p>

                  <p>
                    <strong>Copyright</strong>
                  </p>

                  <p>
                    Under no circumstance can any content on this website be copied or reproduced in
                    any way. This includes any of the reviews and videos that are hosted on the
                    domain, topbuyingguide.com. If any person or company does use the owner of this
                    websites name, video, or blog content without getting written consent from the
                    site then legal action may be undertaken against the offending parties.
                  </p>

                  <p>
                    <strong>What Are Cookies?</strong>
                    <br /> A cookie is a very small text document, which often includes an anonymous
                    unique identifier. When you visit a Web site, that site’s computer asks your
                    computer for permission to store this file in a part of your hard drive
                    specifically designated for cookies. Each Web site can send its own cookie to
                    your browser if your browser’s preferences allow it, but (to protect your
                    privacy) your browser only permits a Web site to access the cookies it has
                    already sent to you, not the cookies sent to you by other sites. Browsers are
                    usually set to accept cookies. However, if you would prefer not to receive
                    cookies, you may alter the configuration of your browser to refuse cookies. If
                    you choose to have your browser refuse cookies, it is possible that some areas
                    of our site will not function as effectively when viewed by the users. A cookie
                    cannot retrieve any other data from your hard drive or pass on computer viruses.
                  </p>

                  <p>
                    <strong>How Do We Use Information We Collect from Cookies?</strong>
                    <br /> As you visit and browse our Website, the site uses cookies to
                    differentiate you from other users. In some cases, we also use cookies to
                    prevent you from having to log in more than is necessary for security. Cookies,
                    in conjunction with our Web server’s log files, allow us to calculate the
                    aggregate number of people visiting our Website and which parts of the site are
                    most popular. This helps us gather feedback to constantly improve our Web site
                    and better serve our clients. Cookies do not allow us to gather any personal
                    information about you and we do not intentionally store any personal information
                    that your browser provided to us in your cookies.
                  </p>

                  <p>
                    <strong>IP Addresses</strong>
                    <br /> IP addresses are used by your computer every time you are connected to
                    the Internet. Your IP address is a number that is used by computers on the
                    network to identify your computer. IP addresses are automatically collected by
                    our web server as part of demographic and profile data known as traffic data so
                    that data (such as the Web pages you request) can be sent to you.
                  </p>

                  <p>
                    <strong>Sharing and Selling Information</strong>
                    <br /> We do not share, sell, lend or lease any of the information that uniquely
                    identifies a subscriber (such as email addresses or personal details) with
                    anyone except to the extent it is necessary to process transactions or provide{' '}
                    <strong>services that you have requested.</strong>
                  </p>

                  <p>
                    <strong>How Can You Access and Correct Your Information?</strong>
                    <br /> You may request access to all your personally identifiable information
                    that we collect online and maintain in our database by emailing us at the usual
                    address.
                  </p>

                  <p>
                    <strong>What About Legally Compelled Disclosure of Information?</strong>
                    <br /> We may disclose information when legally compelled to do so, in other
                    words, when we, in good faith, believe that the law requires it or for the
                    protection of our legal rights. We may also disclose account information when we
                    have reason to believe that disclosing this information is necessary to
                    identify, contact or bring legal action against someone who may be violating our
                    Terms of Service or to protect the safety of our users and the Public.
                  </p>

                  <p>
                    <strong>What About Other Web Sites Linked to Our Web Site?</strong>
                    <br /> We are not responsible for the practices employed by Web sites linked to
                    or from our Website or the information or content contained therein. Often links
                    to other Websites are provided solely as pointers to information on topics that
                    may be useful to the users of our Web site.
                  </p>

                  <p>
                    Please remember that when you use a link to go from our Website to another
                    website, our Privacy Policy is no longer in effect. Your browsing and
                    interaction on any other website, including web sites, which have a link on our
                    Website, is subject to that Web site’s own rules and policies. Please read over
                    those rules and policies before proceeding.
                  </p>

                  <p>
                    <strong>Your Consent</strong>
                    <br /> By using our Website you consent to our collection and use of your
                    personal information as described in this Privacy Policy. We reserve the right
                    to amend this privacy policy at any time with or without notice.
                  </p>

                  <p>
                    <strong>Our Commitment To Data Security:</strong>
                  </p>

                  <p>
                    Please note that your information will be stored and processed on our computers
                    in the United States. The laws on holding personal data in the United States may
                    be less stringent than the laws of your Country of residence or citizenship. To
                    prevent unauthorized access, maintain data accuracy, and ensure the correct use
                    of information, we have put in place appropriate physical, electronic, and
                    managerial procedures to safeguard and secure the information we collect online.
                  </p>

                  <p>
                    <strong>Choice/Opt-In/Opt-Out</strong>
                    <br /> This site allows visitors to unsubscribe so that they will not receive
                    future messages. After unsubscribing we will discontinue sending the particular
                    messages as soon as technically feasible.
                  </p>

                  <p>
                    <strong>A Special Note About Children</strong>
                  </p>

                  <p>
                    Children are not eligible to use our services unsupervised and we ask that
                    children (under the age of 14) do not submit any personal information to us. If
                    you are a minor, you can use this service only in conjunction with permission
                    and guidance from your parents or guardians.
                  </p>

                  <p>
                    <strong>
                      Acquisition or Changes in Ownership
                      <br />{' '}
                    </strong>
                    In the event that the website (or a substantial portion of its assets) is
                    acquired, your information would be considered part of those assets and may be
                    part of those assets that are transferred.
                  </p>

                  <p>
                    <strong>Policy Modifications</strong>
                    <br /> We may change this Privacy Policy from time to time. If/when changes are
                    made to this privacy policy, we will email users who have given us permission to
                    do so. We will post any changes here, so be sure to check back periodically.
                    However, please be assured that if the Privacy Policy changes in the future, we
                    will not use the personal information you have submitted to us under this
                    Privacy Policy in a manner that is materially inconsistent with this Privacy
                    Policy, without your prior consent.
                  </p>

                  <p>
                    <strong>Last Edited April 11, 2018</strong>
                  </p>

                  <p>
                    If you require any other information or have any questions about our privacy
                    policy,
                    <br /> please{' '}
                    <a href="mailto:topbuyingguide@gmail.com" target="_blank" rel="nofollow">
                      contact us here
                    </a>
                     without any hesitation.
                  </p>
                </div>
              </div>
            </PostFullContent>
          </article>
        </div>
      </main>
      <Footer />
    </Wrapper>
  </IndexLayout>
);

export default PrivacyPolicy;
